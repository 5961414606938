@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@fontsource/press-start-2p/400.css';

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.App-logo {
  width: 50vmin;
  pointer-events: none;
  top: 12vh;
  position: absolute;
  transform: translate3D(0, 0, 0) perspective(97px) rotateX(-34deg);
}

.App-subheader {
  position: absolute;
  top: 500px;
}

.App-header {
  height: 20em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #807e84;
}

.App-link {
  color: #61dafb;
}

a:hover {
  text-decoration: underline;
}

a:active {
  color: black;
}

a:visited {
  color: #fff;
}

.fullWidth {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  padding-top: 5em;
  padding-bottom: 10rem;
}

.secondary {
  background-color: #fff;
}

.accent {
  background-color: #302730;
  color: #fff;
  margin-top: 186px;
}

.g_logo {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.1px #f1f1f1;
  font-family: 'Righteous', cursive;
  font-size: 60px;
  -webkit-text-stroke: 4px #f1f1f1;
}

.g_logo:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /*-webkit-animation:vectro_effect 0.067s infinite;*/
  background: repeating-linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0px, rgba(255, 255, 255, 0.05) 1px, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0) 2px);
}

.g_logo_body {
  -webkit-background-clip: text;
  background-image: -webkit-linear-gradient(#C3BFB4 0%, #FDFCFA 50%, #E8E7E5 51%, #757172 52%, #E8E9DB 100%);
  -webkit-filter: drop-shadow(2px 2px 15px #3F59F4);
}

.g_logo-red {
  color: #F10C20;
  -webkit-text-fill-color: #F10C20;
  -webkit-text-stroke: 0;
  -webkit-filter: drop-shadow(2px 2px 15px #F10C20);
  font-size: 100%;
}

.logo_small {
  font-size: 24px;
}

.logo_big {
  font-size: 24px;
}

.navbar {
  font-size: 18px;
  padding-bottom: 10px;
  position: sticky;
  top: 0;
}

.main-nav {
  list-style-type: none;
  display: none;
}

.nav-links,

.logo {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
}

.nav-logo {
  height: 1em;
}

.nav-toggle {
  height: 1em;
  filter: invert(0.5);
}

.main-nav li {
  text-align: center;
  margin: 15px auto;
}

.logo {
  display: inline-block;
  font-size: 22px;
  margin-top: 10px;
  margin-left: 20px;
}

.navbar-toggle {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-size: 24px;
}

.active {
  display: block;
}

@media screen and (min-width: 768px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
    height: 70px;
    align-items: center;
  }

  .main-nav {
    display: flex;
    margin-right: 30px;
    flex-direction: row;
    justify-content: flex-end;
  }

  .main-nav li {
    margin: 0;
  }

  .nav-links {
    margin-left: 30px;
  }

  .logo {
    margin-top: 0;
  }

  .navbar-toggle {
    display: none;
  }

  .logo:hover,
  .nav-links:hover {
    color: rgba(255, 255, 255, 1);
  }

  .logo_big {
    font-size: 60px;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}